.gobackBtn {
    border: 1px solid rgb(239 42 42) !important;
    color: black !important;
    font-weight: 600;
    font-size: 16px;
    background-color: white;
}
.gobackBtn:hover{
    color: rgb(239 42 42) !important;
}
.goStoreBtn {
    border: 1px solid #20c997 !important;
    color: black !important;
    font-weight: 600;
    font-size: 16px;
    background-color: white;
}
.goStoreBtn:hover{
    color: #20c997 !important;
}