@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&family=Lexend:wght@300;400;600&display=swap');

.item-details {
    font-family: 'Lexend', sans-serif !important;
}
.ant-image .ant-image-mask {
    position: absolute;
    inset: 0;
    display: flex;
    margin-top: 1em !important;
    border-radius: 12px !important;
    align-items: center;
    height: 275px !important;
    justify-content: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    /* opacity: 0; */
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    -ms-border-radius: 12px !important;
    -o-border-radius: 12px !important;
}
.item-img {
    background-color: #fff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    max-height: 400px;
    /* max-width: 400px; */
    object-fit: cover;
    overflow: hidden;
    position: relative;
    transition: -webkit-transform 1s ease;
    transition: transform 1s ease;
    transition: transform 1s ease,-webkit-transform 1s ease;
}
.notification {
    color: #fff;
}