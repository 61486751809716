.ant-segmented-group {
    background-color: white;
    border-radius: 50px;   
}
.ant-segmented-item-selected {
    background-color: #48A0DB !important;
    border-radius: 50px !important;
    color: white !important;
}
.ant-segmented-item{
    border-radius: 50px;
    width: 100px;
    padding: 0.2em;
    color: #000 ;
}
.ant-segmented-item:hover{
    border-radius: 50px;
    /* background-color: white !important; */
}
.btns-div{
    scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
  overflow: -moz-scrollbars-none; /* Hide scrollbar in older versions of Firefox */
  -webkit-overflow-scrolling: touch; 
  
}
.btns-div::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers */
  }
 
  
  /* .skelton-img .ant-skeleton.ant-skeleton-active .ant-skeleton-image {
    width:100% !important;
    display: block !important;
  }
 .skelton-img .ant-skeleton.ant-skeleton-element {
    width: auto !important;
  }
   */
  
  
  
  
/* :where(.css-dev-only-do-not-override-12jzuas).ant-segmented .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled)::after{
    background-color: transparent;
    color: black;
} */