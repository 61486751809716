@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&display=swap');
*{
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}
.mainDiv{
  position: absolute;
  height: auto;
   min-height: 100%;
   width: 500px;
   max-width: 500px;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@400;600;700&family=Bebas+Neue&family=PT+Sans:wght@400;700&family=Playfair+Display:wght@400;700&family=Poppins:wght@400;500;600;700&family=Radley&family=Readex+Pro:wght@400;500;600;700&family=Roboto:wght@400;700&display=swap');


*{
  font-family:var(--fontOfLanguage) ;
}
@media all and (min-width:769px) and (max-width:1000px) {
  /* .mainDiv{
    width: 60%;
  } */
  
}
@media all and (max-width:768px) {
  .mainDiv{
    width: 100%;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: rgba(3, 3, 3, 0.327);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}