.headerBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .loginSignup {
    order: 2;
    margin-bottom: 0px;
}
.segmentedBtns{
    order: 1;
} */
@media all and (min-width: 341px) and (max-width: 410px){ 

    .segmentedBtns{
        width: 68% !important;
        font-size: 10px;
    }
    .segmentedBtns svg {
        width: 15%;
        font-size: 27px !important;
    }
    .segmentedBtns .ant-segmented {
        font-size: 10px;
        width: 160px;
    }
    .loginSignup {
        width: 95px;
        font-size: 10px;
        padding: 0em 0.3em !important;
    }
    :where(.css-dev-only-do-not-override-1m62vyb).ant-segmented .ant-segmented-item-label {
        padding: 0px !important;
    }
    .ant-segmented-item-label {
        padding: 0px !important;
    }
    :where(.css-1m62vyb).ant-segmented .ant-segmented-item-label {
        padding: 0px !important;
    }

}

@media all and (min-width: 300px) and (max-width: 340px){
    /* .headerBtns {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        
    }
    .loginSignup {
        order: 1 !important;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .segmentedBtns{
        order: 2 !important;
    } */
    .segmentedBtns{
        width: 68% !important;
        font-size: 10px;
    }
    .segmentedBtns svg {
        width: 15%;
        font-size: 27px !important;
    }
    .segmentedBtns .ant-segmented {
        font-size: 10px;
        width: 140px;
    }
    .loginSignup {
        width: 95px;
        font-size: 10px;
        padding: 0em 0.3em !important;
    }
    :where(.css-dev-only-do-not-override-1m62vyb).ant-segmented .ant-segmented-item-label {
        padding: 0px !important;
    }
    .ant-segmented-item-label {
        padding: 0px !important;
    }
    :where(.css-1m62vyb).ant-segmented .ant-segmented-item-label {
        padding: 0px !important;
    }
}
@media all and (max-width: 299px) {
    .segmentedBtns{
        width: 68% !important;
        font-size: 10px;
    }
    .segmentedBtns svg {
        width: 15%;
        font-size: 25px !important;
    }
    .segmentedBtns .ant-segmented {
        font-size: 10px;
        width: 120px;
    }
    .loginSignup {
        width: 95px;
        font-size: 10px;
        padding: 0em 0.3em !important;
    }
    :where(.css-dev-only-do-not-override-1m62vyb).ant-segmented .ant-segmented-item-label {
        padding: 0px !important;
    }
    .ant-segmented-item-label {
        padding: 0px !important;
    }
    :where(.css-1m62vyb).ant-segmented .ant-segmented-item-label {
        padding: 0px !important;
    }
}

.drawer-container{
    max-width: 500px;
    background-color: #F5F5F5 !important;
    /* box-shadow: 0 1px 2px rgba(0,0,0,0.5) !important; */
}