.modal{
    max-width: 500px !important;
    overflow-x: hidden  !important;
}

.succesModal .ant-modal-content {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}
.succesModal .ant-modal-content .ant-modal-body{
    display: flex !important;
   
    justify-content: center !important;
    align-items: center !important;
}
.wpwl-form-has-inputs {
    background-color: #ccc !important;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: 2px 3px 5px 1px #999;
    box-shadow: 2px 3px 5px 1px #999;
}
.wpwl-form .wpwl-form-registrations .wpwl-clearfix {
    max-width: 40em;
    position: fixed  !important;
    bottom: 0 !important;
}
.wpwl-wrapper .wpwl-wrapper-submit{
    margin-top: -30px !important;
}
.wpwl-button-pay {
    float: right;
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;

}
.wpwl-button {
    border-radius: 4px;
}

/* .wpwl-button-pay {

} */
