.select-address .ant-select-selector {
  padding: 1.6em 1em !important;
  border-radius: 10px;
}
.select-address .ant-select-selection-item {
  /* padding: 1.6em 1em !important; */
  /* border-radius: 10px; */
  z-index: 99;
  position: absolute !important;
  top: 8px;
}
.select-address  .ant-select-selection-placeholder {
  padding-inline-end: 18px;
  z-index: 99;
  position: absolute !important;
  top: 8px !important;
}
.select-address .ant-select-show-search {
  padding: 1.6em 1em !important;
  border-radius: 10px;
}
.bg {
  /*height: inherit;
  width: 100%;
  *//*background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px; */
  max-height: 240px;
  max-width: 100%;
  /* height: auto; */
  position: absolute;
  top: 0;
  right: 0;
  /*max-width: 80px;
  */object-fit: cover;
  overflow: hidden;
  /* position: relative; */
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease,-webkit-transform 1s ease;
  -webkit-transition: transform 1s ease,-webkit-transform 1s ease;
  -moz-transition: transform 1s ease,-webkit-transform 1s ease;
  -ms-transition: transform 1s ease,-webkit-transform 1s ease;
  -o-transition: transform 1s ease,-webkit-transform 1s ease;
}

.landing-bg-div {
  width: 100%;
  display: flex !important;
  position: relative;
  /* background-color: rgb(65, 166, 244); */
  /* margin-top: 1em; */
  height: 260px;
  border-radius: 15px;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important; 
  object-fit: cover !important;
  overflow: hidden;
}
.title-div {
  width: 60%;
  padding: 2em 0em 2em 1.5em ; 
}
.landing-title{
    
        color: white;
        font-size: 26px;
        font-weight: 700;
        width: 100%;
      
}
.landing-subtitle{
    margin-top: 0.6em; 
    color: white;
    font-size: 14px;
    font-weight: 500;
}
.bg-div {
  width: 58%;
  height: 250px;
  border-radius: inherit;
  padding: 0em 2.5em 0em  0em ;
}
.stores-div{
  
    width: 100%;
    margin: 0em auto;
    display: flex;
    box-sizing: border-box;
    /* padding: 0em 1em; */
    border: none;
    outline: none;
    border-radius: 10px;
   
   
   
  
}

.stores-div {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* For Webkit-based browsers (Chrome, Safari) */
.stores-div::-webkit-scrollbar {
  width: 6px;
}

.stores-div::-webkit-scrollbar-track {
  background-color: transparent;
}

.stores-div::-webkit-scrollbar-thumb {
  background-color: transparent;
}






.stores-div .store {
  
    width: 220px;
    border-radius: 15px;
    margin: 1em 0.35em;
    box-shadow: 0 0 8px lightgray;
    cursor: pointer;
}

.stores-div .picks {
  
  width: 100%;
  border-radius: 15px;
  margin: 1em 0.35em;
  

}
.stores-div> :first-child {
  margin: 1em 0.35em 1em 0em;
}
.ant-carousel, .ant-carousel .slick-slider, .ant-carousel .slick-slider .slick-list {
  border-radius: 15px !important;
}

.ant-carousel .slick-slider ul, .ant-carousel .slick-slider .slick-dots {
  position: absolute !important;
  width: 80% !important;
  bottom: 20px !important;
  left: 1.5em !important;
  display: flex;
  justify-content: flex-start !important;
}

@media all and (max-width: 400px) {
  /* .landing-bg-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    background-color: rgb(65, 166, 244);
    margin-top: 1em;
    height: 250px;
    border-radius: 15px;
  } */
  /* .title-div {
    width: 100%;
    padding: 1.5em 1em;
    height: 15%;
    padding: 0.5em;
  }
  .bg-div {
    width: 90%;
    height: 85%;
  }
  .bg-div img {
    width: 80%;
    height: inherit;
   
  } */
  .bg-div{
    width: 50%;
  }
  .title-div{
    width: 15%;
  }
  .landing-title{
    font-size: 20px;
    width: 150%;
  }
   .landing-subtitle {
    font-size: 14px;
    width: 150%;
  }

}
@media all and (min-width: 321px) and (max-width: 420px) {
    .bg-div{
        width: 55%;
      }
      .bg {
        right: -17px;
      }
      .title-div{
        width: 35%;
        padding: 2em 0em 0em 1em ; 
      }
      .landing-title{
        font-size: 22px;
        width: 160%;
      }
       .landing-subtitle {
        font-size: 14px;
        width: 110%;
      }
      .landing-bg-div {
        height: 200px;
      }
  }
  @media all and (min-width: 400px) and (max-width: 420px) {
    .bg-div{
        width: 55%;
      }
      .bg {
        right: -17px;
      }
      .title-div{
        width: 35%;
      }
      .landing-title{
        font-size: 23px;
        width: 150%;
      }
       .landing-subtitle {
        font-size: 14px;
        width: 120%;
      }
          .landing-bg-div {
        height: 200px;
      }
  }
  @media all and  (max-width: 332px) {
    .bg-div{
        /* width: %; */
      }
      .bg {
        right: -17px;
      }
      .title-div{
        width: 38%;
      }
      .landing-title{
        font-size: 20px;
        width: 120%;
      }
       .landing-subtitle {
        font-size: 14px;
        width: 100%;
      }
        .landing-bg-div {
        height: 200px;
      }
  }
  .select-address .ant-select-selector .ant-select-selection-search-input {
    height: 42px !important;
}
   /* :where(.css-dev-only-do-not-override-12jzuas).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 42px;
}
 */
 .itemlanding-img {
  /* background-color: #fff; */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  max-height: 200px;
  /* max-width: 400px; */
  object-fit: cover;
  overflow: hidden;
  position: relative;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease,-webkit-transform 1s ease;
}