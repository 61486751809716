.otpDiv {
    width: 50%;
    margin: 2em auto;
    border: 1px solid #F6A94A;
    border-radius: 15px;
    text-align: center;
    padding: 2em 2em 0em;
  }
  .otpDiv input {
    border: 1px solid #F6A94A;
    border-radius: 8px;
    width: 8% !important;
    height: 35px;
    font-size: 16px;
    color: black;
    font-weight: 400;
    caret-color: blue;
  
    margin: 30px 12px 0 12px;
  }
  @media all and (min-width: 600px) and (max-width: 900px) {
    .otpDiv {
      width: 60%;
    }
    .otpDiv input {
      width: 25% !important;
      margin: 1em 0.25em;
    }
  
  }
  @media all and (max-width: 600px) {
    .otpDiv {
        width: 90%;
      }
  }